<template>
  <div class="nHome-detail beautys-detail-div" v-if="info">
    <van-nav-bar :title="info.xuanfei_name" class="nav-bar">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()" />
      </template>
    </van-nav-bar>
    <div style="height: 0px;"></div>

    <div class="feiziInfo">
      <div class="topInfo">
        <div><span>みほ</span></div>
        <div style="width: 70%;line-height: 1.5;">{{ $t('xuanfei.height') }}:{{ info.height }} {{ $t('xuanfei.weight') }}:{{ info.weight }} {{
          $t('xuanfei.round1') }}: {{ info.one }} {{ $t('xuanfei.round2') }}:{{ info.two }}

          {{ $t('xuanfei.round3') }}:{{ info.three }}
</div>
        <div class="tags">
          <van-tag v-for="(li, indexs) in info.service_data" :key="indexs" size="large" type="primary">{{ li }}</van-tag>
        </div>
        <div><span style="color: rgb(107, 34, 179); font-size: 14px;">課金区間：</span>
          <van-rate v-model="value" size="20" />

        </div>
        <div style="color: rgb(107, 34, 179); font-size: 14px; margin: 4px 0px;">{{ $t('xuanfei.serveCity') }}:{{ info.address }} </div>
        <div class="yuyueBox">今すぐ予約</div>
        <div class="dianzanimg">
          <div class="van-image" style="width: 60px; height: 60px;"><img src="img/au/zan.png"
              class="van-image__img" style="object-fit: cover;"></div>
          <p style="color: rgb(107, 34, 179); margin: 0px;">1028</p>
        </div>
      </div>
      <div class="cenInfo">
        <van-tabs v-model="active">
          <van-tab title="写真">
            <div class="imgswiper">

              <div v-for="(item, index) in info.img_url" :key="index" style="width: 100px; height: 100px; margin-right: 10px;">
                
          <van-image class="imgI" :src="item" style="height: 100%;width: 100%;" :show-loading="false" fit="contain"
            @click="active_img = item" />
              </div>
             
            </div>
            <div>
              <van-image :src="active_img" style="height: 100%;width: 100%;" :show-loading="false" fit="contain" />
            </div>
          </van-tab>
          <van-tab title="ビデオ">
            <p v-html="info.content"></p></van-tab>

        </van-tabs>
      </div>
      <div class="btmInfo">
        <div style="color: rgb(42, 20, 104); margin-bottom: 10px; font-weight: bold;"> 個人プロフィール</div>
        <div style="color: rgb(83, 69, 137);"></div>
      </div>
    </div>
    <!-- swiper1 -->
    <!-- <div class="banner" style="position: relative;">

      <swiper class="swiper gallery-top" :options="swiperOptionTop" ref="swiperTop" style="position: relative;">
        <swiper-slide class="slide-1" style="text-align: center;" v-for="(item, index) in info.img_url" :key="index">
          <van-image :src="item" style="height: 100%;width: 100%;" :show-loading="false" fit="contain"
            @click="openImg(index)" />
        </swiper-slide>

      </swiper>
      <div class="bottom-logo-div"><img class="logo-img" src="">
        <div>
          <div><img src="@/assets/v1.png"><span>{{ $t('xuanfei.Authentication') }}</span>
          </div>
          <div><img src="@/assets/v1.png"><span>{{ $t('xuanfei.VideoAuthentication') }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="detailInfo-div">
      <ul>

        <li><span>{{ info.xuanfei_name }}</span></li>
        <li><span>{{ $t('xuanfei.Passenger_price') }}: {{ info.price }}</span>
        </li>
        <li>{{ $t('xuanfei.Service') }}</li>
        <li>{{ $t('xuanfei.height') }}:{{ info.height }} {{ $t('xuanfei.weight') }}:{{ info.weight }} {{
          $t('xuanfei.round1') }}: {{ info.one }} {{ $t('xuanfei.round2') }}:{{ info.two }}

          {{ $t('xuanfei.round3') }}:{{ info.three }}

        </li>
        <li>
          <div v-for="(li, indexs) in info.service_data" :key="indexs">{{ li }}</div>
        </li>
        <li>
          <ul>
            <li>{{ $t('xuanfei.serveCity') }}:{{ info.address }}</li>
            <li>{{ $t('xuanfei.Airborne') }}: {{ $t('xuanfei.yes') }}</li>
          </ul>
          <div @click="$router.push('ServiceOnline')">{{ $t("my.online_service") }}</div>
        </li>
      </ul>
      <div class="detail-imgs-div">
        <span>{{ $t('xuanfei.summary') }}</span>

        <p v-html="info.content"></p>
      </div>
    </div> -->
  </div>
</template>

<script>
import { ImagePreview } from 'vant';

import dayjs from 'dayjs'
export default {
  data() {
    return {
      btnLogin: false,
      info: null,
      commentlist: [],
      dayjs,
      active_img:''
    };
  },
  created() {
    this.$http({
      method: 'get',
      url: 'xuanfeidata',
      data: { id: this.$route.query.id }
    }).then(res => {
      console.log(res.data)
      this.info = res.data
      this.active_img = res.data.img_url[0]

    })

  },

  mounted() {
  },
  methods: {
    openImg(index) {
      let that = this;
      ImagePreview({
        images: that.info.img_url,
        startPosition: index,
      });
    },
    back() {
      if (this.url) {

        this.$router.replace({ path: '/au/auMine' })
      } else {
        window.history.back();
      }
    },

  }
};
</script>

<style scoped lang="less">
.nHome-detail {
  background: #f2f2f5;
  height: 100vh;
  overflow: auto;
  color: #000;
}



.swiper {}


.swiper-slide {
  background-size: cover;
  background-position: center;
}

.gallery-top {
  height: 600px;
}



.nav-bar {
  // background: linear-gradient(30deg, #7d76ef, #d63f8c);
  height: 100px;
  // background: url('../../assets/topbar_bg.png');

  background: linear-gradient(30deg, #7d76ef, #d63f8c);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.banner {
  position: relative
}

.banner .full-class {
  padding: 0 !important
}

.banner .swiper-container {
  width: 100%;
  // padding: 20px 0
}

.banner .swiper-container .swiper-wrapper .swiper-slide .padding-b {
  position: relative;
  padding-bottom: 100%
}

.banner .swiper-container .swiper-wrapper .swiper-slide .padding-b .img-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0
}

.banner .swiper-container .swiper-wrapper .swiper-slide .padding-b .img-wrapper .img {
  width: 100%;
  height: 100%
}

.banner .swiper-container .swiper-wrapper .swiper-slide.swiper-slide-active {
  transform: scale(1)
}

.banner .bottom-logo-div {
  position: absolute;
  z-index: 1000;
  bottom: 0;
  width: 100%;
  height: 80px;
  padding: 0 20px;
  border-radius: 40px 40px 0 0;
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: space-between
}

.banner .bottom-logo-div>img {
  height: 80px;
  -o-object-fit: contain;
  object-fit: contain
}

.banner .bottom-logo-div>div {
  display: flex;
  align-items: center
}

.banner .bottom-logo-div>div>div:first-child {
  margin-right: 8px;
  border-radius: 0 0 0 30px
}

.banner .bottom-logo-div>div>div:nth-child(2) {
  border-radius: 0 30px 0 0
}

.banner .bottom-logo-div>div>div {
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(180deg, #e7caaf, #fff7ed);
  color: #a4826b;
  font-weight: 600;
  padding: 8px 30px;
  font-size: 30px
}

.banner .bottom-logo-div>div>div img {
  height: 20px
}




.detailInfo-div {
  padding: 30px 20px
}

.detailInfo-div>ul {
  width: 100%;
  display: flex;
  flex-direction: column
}

.detailInfo-div>ul>li {
  display: flex;
  align-items: center
}

.detailInfo-div>ul>li:first-child {
  margin-bottom: 30px
}

.detailInfo-div>ul>li:first-child>span:first-child {
  font-size: 30px;
  font-weight: 500
}

.detailInfo-div>ul>li:first-child>span:nth-child(2) {
  font-size: 28px;
  color: #f2b247
}

.detailInfo-div>ul>li:nth-child(2) {
  margin-bottom: 20px
}

.detailInfo-div>ul>li:nth-child(2) span:first-child {
  font-size: 28px;
  color: #ff207d;
  margin-right: 20px
}

.detailInfo-div>ul>li:nth-child(2) span:nth-child(2) {
  font-size: 28px;
  text-decoration: line-through
}

.detailInfo-div>ul>li:nth-child(3) {
  margin-bottom: 30px
}

.detailInfo-div>ul>li:nth-child(3) span {
  font-size: 28px;
  color: #666
}

.detailInfo-div>ul>li:nth-child(3) span:first-child {
  margin-right: 20px
}

.detailInfo-div>ul>li:nth-child(4) {
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 30px
}

.detailInfo-div>ul>li:nth-child(5) {
  font-size: 28px;
  margin-bottom: 20px
}

.detailInfo-div>ul>li:nth-child(5) {
  flex-wrap: wrap;
  margin-bottom: 10px
}

.detailInfo-div>ul>li:nth-child(5)>div {
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 10px 30px;
  font-size: 32px;
  background: linear-gradient(30deg, #7d76ef, #d63f8c);
  border-radius: 6px;
  margin-bottom: 10px
}

.detailInfo-div>ul>li:nth-child(4) {
  justify-content: space-between;
  align-items: flex-end
}

.detailInfo-div>ul>li:nth-child(4)>ul {
  flex: 1
}

.detailInfo-div>ul>li:nth-child(5)>ul>li {
  font-size: 28px;
  display: flex;
  align-items: center;
  margin-bottom: 10px
}

.detailInfo-div>ul>li:nth-child(5)>ul>li:first-child>span:first-child {
  margin-right: 10px
}

.wujiaoxing-span {
  display: block;
  width: 30px;
  height: 30px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAaCAYAAACkVDyJAAAACXBIWXMAAAsTAAALEwEAmpwYAAABl0lEQVRIie3WMUhVURzH8c97WQg6VJIICeLUENIgDa+GmnSQCCKIHINqCkRoKWiwRVtcogjMzcVJHWrt4RBOWWu4iLW0NIRD8cjh3IeHy70+37vvbv7gDPd//ud+/3/O+f/Pqfz5eE8Heoo1fG93YbUD2CXM4XUHa9sGVvAOvZjA3bKBD3Aj+l5Ef1nAQbxK2YbxoizgIs5n2GdwudvASUznzJ0WDlClG8AqxvCmhd9NPMKpVsBKqg77cBXXUcM1nGv1k0i/sYlPqGMbjdihB7eTCGsYT2yd6ixuJSMOoJ4Esd2DAWHjy1AcwC/UqljGQknApvYT6E7z0DzHRkmwBu5ji8NT2jR+KwH4RJRMXBb7mMLPLsLm8TY2pOtwD3cSeFGt4FnamFX4W0IRF9Gm0Oj/HwcIXwoCP+Bv1kQecKggMKvJHwm8UBCY2w5PMszSrnAZH9Uk2gamM2xgXWgMo5jFFeGWWcW/lP9AHjDvKmpmuIslvJfdgerJuIjHeJisbXsPPzvM5mUOLNYP4TE1IjxFvuJMluMB65BGG6uhkFoAAAAASUVORK5CYII=) no-repeat 50%/100%
}

.detailInfo-div>ul>li:nth-child(6)>div {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 10px 30px;
  font-size: 32px;
  background: linear-gradient(20deg, #e73266, #ee5380);
  border-radius: 6px
}

.detailInfo-div .detail-imgs-div>span {
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 30px
}

.detailInfo-div .detail-imgs-div>img {
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  margin-bottom: 10px
}



.beautys-detail-div .describe-div {
  padding: 60px 20px;
  display: flex;
  flex-direction: column
}

.beautys-detail-div .describe-div>span:first-child {
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 20px
}

.beautys-detail-div .describe-div>span:nth-child(2) {
  font-size: 30px
}



.feiziInfo {
  background: #ebedf0
}

.feiziInfo .topInfo {
  position: relative
}

.feiziInfo>div {
  padding: 4vw 2.667vw;
  background: #fff;
  margin-bottom: 4vw
}

.feiziInfo .topInfo>div:first-child {
  font-weight: 600
}

.feiziInfo .topInfo>div:first-child>span:first-child {
  color: #2c1868;
  font-size: 5vw
}

.feiziInfo .topInfo>div:nth-child(2) {
  color: #6b22b3;
  font-size: 3vw;
  margin: 1.333vw 0
}

.feiziInfo .topInfo>div:nth-child(3) {
  width: 50%
}

.feiziInfo .topInfo>div:nth-child(3)>div {
  background: linear-gradient(50deg, #9b54ca, #e6557f);
  color: #fff;
  display: inline-block;
  border-radius: 1.333vw;
  margin-right: 3.333vw;
  font-size: 2.933vw;
  padding: 2.4vw;
  margin-bottom: 3.333vw
}

.feiziInfo .topInfo .yuyueBox {
  position: absolute;
  right: 4vw;
  bottom: 2.667vw;
  color: #fff;
  padding: 2.667vw 4vw;
  background: linear-gradient(20deg, #e73266, #ee5380);
  border-radius: 7vw
}

.feiziInfo .topInfo .dianzanimg {
  position: absolute;
  top: 4vw;
  right: 7vw;
  text-align: center
}

.feiziInfo .cenInfo .imgswiper {
  width: 100%;
  overflow-x: scroll;
  margin: 4vw 0;
  display: flex;
  flex-wrap: nowrap
}

.van-tab {
  font-size: 4vw;
  line-height: 4vw
}

.van-tabs--line .van-tabs__wrap {
  height: 6.667vw
}

.feiziInfo .cenInfo .imgswiper>div .imgI.active {
  border: .933vw solid #e54878
}

.van-image__error,
.van-image__img,
.van-image__loading {
  display: block;
  width: 100%;
  height: 100%
}

.van-rate__icon--full {
  color: #ffd21e
}

.van-rate__icon--empty {
  color: #e6e2e2
}

.van-tag--large {
  background: linear-gradient(50deg, #9b54ca, #e6557f);
  color: #fff;
  display: inline-block;
  border-radius: 1.333vw;
  margin-right: 3.333vw;
  font-size: 2.933vw;
  padding: 2.4vw;
  margin-bottom: 3.333vw;

}

::v-deep .van-tab {
  font-size: 30px;
  line-height: 100px;
  font-weight: bold;
}

::v-deep .van-tabs__line {
  background-color: #775fd9;
}

::v-deep .van-tabs--line .van-tabs__wrap {
  // height: 100px;
}

::v-deep .van-tabs__wrap--scrollable .van-tab {
  padding: 0 23px;
}
</style>
