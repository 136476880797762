<template>
  <div id="app">
    <div class="iframe" v-if="screenWidth > 1000">
      <iframe style="width: 650px;height: 100vh;margin: auto;text-align: center;" :src="baseURL" frameborder="0"></iframe>
    </div>
    <template v-else>
      <router-view></router-view>
      <Footer />
    </template>
  </div>
</template>

<script>
function getUrlParams3(url) {
  let pattern = /(\w+|[\u4e00-\u9fa5]+)=(\w+|[\u4e00-\u9fa5]+)/ig;
  let result = {};
  url.replace(pattern, ($, $1, $2) => {
    result[$1] = $2;
  })
  return result
}
import Footer from './common/Footer'
export default {
  name: 'app',
  components: {
    Footer
  },
  data() {
    return {
      status: 0,
      screenWidth: null,
      baseURL: window.location.origin,
      lang: localStorage.getItem("viodeLang") || this.$store.state.langDefault,
      checkloginoutTime: null
    };
  },
  methods: {
    getBaseInfo() {
      this.$http({
        method: 'get',
        url: 'base_info'
      }).then(res => {
        if (res.code == 200) {
          console.log(res)

          if (res.data.login_out == 1) {

            this.$http({
              url: "checkloginout",
              method: "get",
            }).then((ret) => {
              console.log(ret)
              if (ret.code === 10001) {
                this.$toast(ret.msg);
                  setTimeout(()=>{

                    localStorage.clear()
                                    this.$router.push({ path: '/Login' });
                                    clearInterval(this.checkloginoutTime)
                  },1500)
              }
            });
            this.checkloginoutTime = setInterval(() => {

              this.$http({
                url: "checkloginout",
                method: "get",
              }).then((ret) => {
                if (ret.code === 10001) {
                  this.$toast(ret.msg);
                  setTimeout(()=>{

                    localStorage.clear()
                                    this.$router.push({ path: '/Login' });
                  },1500)
                }
              });
            }, 3000)
            }

          if (!localStorage.getItem('token') && res.data.video_login == 1) {
            this.$router.push({ path: '/Login' })
          }
          this.$store.commit('setBaseInfoValue', res.data);
        }
      })
    }

  },
  watch: {
    screenWidth: function (n) {
      this.screenWidth = n
      if (n <= 1200) {
        this.screenWidth = n
      }
    }
  },
  mounted() {
    //获取屏幕尺寸
    this.screenWidth = document.body.clientWidth
    window.onresize = () => {
      //屏幕尺寸变化
      return (() => {
        this.screenWidth = document.body.clientWidth
      })()
    }

    let urlParams = getUrlParams3(window.location.href)
    if(urlParams.invite_code){
      
      localStorage.setItem("invite_code",urlParams.invite_code);
    }
    if (urlParams.sso && urlParams.username) {
      this.$http({
        url: "doLoginthird",
        method: "post",
        data: {
          ...urlParams,
          lang: this.lang
        },
      }).then((res) => {
        if (res.code === 200) {
          localStorage.setItem("tokensso", res.data.tokensso);
          localStorage.setItem("token", res.data.id);
          this.getBaseInfo()
        }
      });
    } else {

      this.getBaseInfo()
    }
  },

  created() {
    // this.getBaseInfo();

  },
  destroyed() {
    // clearInterval(this.checkloginoutTime)
  }
}
</script>

<style>
body .van-toast {
  font-size: 38px;
  padding: 30px;
  line-height: 50px;
  width: 70vw;
}

body .van-toast .van-toast__icon {
  font-size: 50px;
}

*,
:after,
:before {
  box-sizing: border-box;
}

.iframe {
  display: flex;
  justify-content: center;
  align-items: center;
}</style>
