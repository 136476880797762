<template>
    <div id="au">
        <router-view></router-view>
        <Footer />
    </div>
  </template>
  
  <script>
  import Footer from './tabbar/Footer.vue'
  export default {
    name: 'au',
    components: {
      Footer
    },
    data() {
      return {
        status: 0,
        screenWidth: null,
        baseURL: window.location.origin,
        lang: localStorage.getItem("viodeLang") || this.$store.state.langDefault,
        checkloginoutTime: null
      };
    },
  
    created() {
      // this.getBaseInfo();
  
    },
    destroyed() {
      // clearInterval(this.checkloginoutTime)
    }
  }
  </script>
  
  <style>
.auhomepage {
    background: #fffeef !important;
}</style>
  