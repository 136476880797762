<template>
    <div>

        <van-nav-bar class="nav-bar">
            <template #left>
                <!-- <van-icon name="arrow-left" color="#fff" @click="back()" /> -->
                <van-icon @click="toHome" name="wap-home-o" color="#333" size="36" />
            </template>
            <template #right>
                <img @click="toServe" style="width: 36px;" src="/img/au/kefu.png" />
            </template>
        </van-nav-bar>
        <div class="wrapper homepage">
            <!-- <div class="ifm">
                <div class="left" style="width: 50%;">
                    <div><span class="welcome">{{ $t("xuanfei.welcome") }}</span></div>
                    <div style="font-size: 1rem;"><span></span></div>
                </div>
                <div class="right" style="width: 50%;"></div>
                <div class="logo"><img src="/img/au/logo.png" style="width: 45vw;position: relative;" alt="">
                </div>
            </div> -->
            <div class="container" style="margin-top: 10px;">
                <div class="balance-card">
                    <div class="ifmList">
                        <ul>
                            <li>
                                <div class="num">{{ $t("xuanfei.wdye") }}</div>
                                <div class="tips"> {{ $t("reservation.unit") }}{{ userInfo.money }}</div>
                            </li>
                        </ul>
                    </div>
                    <div class="ifmList">
                        <ul>
                            <li>
                                <div class="num">{{ userInfo.commission || 0 }}</div>
                                        <div class="tips">{{ $t("xuanfei.zyj") }} ( {{ $t("reservation.unit") }} )</div>
                            </li>
                            <li>
                                <div class="num">|</div>
                            </li>
                            <li>
                                <div class="num">{{ userInfo.today_commission || 0 }}</div>
                                <div class="tips">{{ $t("xuanfei.jryj") }} ( {{ $t("reservation.unit") }} )</div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="tabsList">
                <ul>
                    <li @click="doPay()">
                        <div>
                            <div><img src="/img/au/recharge.png" alt=""></div>
                            <div>{{ $t('recharge.recharge') }}</div>
                        </div>
                    </li>
                    <li @click="doWithdrawal()">
                        <div>
                            <div><img src="/img/au/withdraw.png" alt=""></div>
                            <div>{{ $t('my.withdraw') }}</div>
                        </div>
                    </li>
                    <li @click="toUrl('/au/invitation')">
                        <div>
                            <div><img src="/img/au/invite.png" alt=""></div>
                            <div>{{ $t('xuanfei.invitation') }}</div>
                        </div>
                    </li>
                    <li @click="toUrl('/au/team')">
                        <div>
                            <div><img src="/img/au/team_report.png" alt=""></div>
                            <div>{{ $t('xuanfei.tdbb') }}</div>
                        </div>
                    </li>
                    <li @click="toUrl('/WithdrawRecord')">
                        <div>
                            <div><img src="/img/au/withdraw_record.png" alt=""></div>
                            <div>{{ $t('withdraw.with_record') }}</div>
                        </div>
                    </li>
                    <li @click="toUrl('/RechargeRecord')">
                        <div>
                            <div><img src="/img/au/recharge_record.png" alt=""></div>
                            <div>{{ $t('recharge.with_record') }}</div>
                        </div>
                    </li>
                    <li @click="toUrl('/setting')">
                        <div>
                            <div><img style="width: 95px;" src="/img/au/setting.png" alt=""></div>
                            <div>{{ $t('setting.setting') }}</div>
                        </div>
                    </li>
                </ul>
            </div>

            <!-- <div class="rockList">
                <div class="titleList">
                    <div class="name" @click="active_h = 1" :class="active_h == 1 ? 'actives':''"><img
                            src="/img/au/recharge_list.png" alt=""><span>{{ $t('xuanfei.yjqd')}}</span></div>
                    <div class="name " @click="active_h = 0" :class="active_h == 0 ? 'actives':''"><img
                            src="/img/au/latest_recharge.png" alt=""><span>{{ $t('xuanfei.zxcz') }}</span>
                    </div>
                </div>
                <div class="contan">
                    <div class="list ">
                        <div class="val">
                            <div class="lef"><img src="/img/au/99.png" alt=""></div>
                            <div class="rig">
                                <div class="nav">{{ $t('xuanfei.gx') }}&nbsp;&nbsp;*****574601</div>
                                <div class="navVal">{{ $t('xuanfei.hq') }} {{ $t("reservation.unit") }}
                                    821.20{{$t('xuanfei.yj')}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->



            <!-- <div style="height: 120px;"></div> -->

        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            active_h: '',
            userInfo: {
            },
        }
    },
    created() {

        if (localStorage.getItem('token')) {
            this.getUserInfo();
            // this.$toast(this.$t("reservation.refresh"));
        } else {
            this.$router.push({ path: '/Login' })
        }
    },
    methods: {
        getUserInfo() {
            this.$http({
                method: 'get',
                url: 'user_info'
            }).then(res => {
                if (res.code === 200) {
                    this.userInfo = res.data;
                    this.menu_top = 15;
                    if (this.userInfo.status !== 1) {
                        this.$toast(this.$t("video.account_out"));
                        localStorage.clear()
                        this.$router.push({ path: '/Login' })
                    }
                } else if (res.code === 401) {
                    this.$toast(res.msg);
                }
            })
        },
        toHome() {
            this.$router.push("/");
        },
        toServe() {
            window.open(this.$store.getters.getBaseInfo.kefu)
        },

        doWithdrawal() {

            if (this.$store.getters.getBaseInfo.close_withdraw == 1) {
                this.$toast.fail(this.$t("setting.doWithdrawal"));
            } else {
                this.$http({
                    method: 'get',
                    url: 'user_get_bank'
                }).then(res => {
                    if (res.data.is_bank) {
                        this.$router.push("/withdraw");
                    } else {
                        this.$router.push("/Setbank");
                        this.$toast.fail(this.$t("setting.set_bank"));
                    }
                })
            }


        },
        doPay() {

            if (!localStorage.getItem('token')) {
                this.$router.push({ path: '/Login' })
            }
            if (this.$store.getters.getBaseInfo.close_withdraw == 1) {
                this.$toast.fail(this.$t("setting.doPay"));
            } else {
                this.$router.push({
                    name: 'Recharge'
                })
            }
        },
        toUrl(url) {

            if (this.$store.getters.getBaseInfo.close_withdraw == 1) {
                this.$toast.fail(this.$t("setting.doPay"));
            }

            if (localStorage.getItem('token')) {
                // this.$router.push({ path: '/Infomation' });

                this.$router.push(url)
            } else {
                this.$router.push({ path: '/Login' })
            }
            // if (this.$store.getters.getBaseInfo.close_withdraw == 1) {
            //     this.$toast.fail(this.$t("setting.doPay"));
            // } else {
            //     this.$router.push({
            //     name: 'Recharge',
            //     params: {
            //         'balance': this.userInfo.money
            //     }
            //     })
            // }
        }
    }
}
</script>
<style lang="less" scoped>
.ifm {
    display: flex;
    padding: 1.5vh 20px;
    background-color: #ffb900;
    flex-direction: column;
    margin-bottom: 20px;
}

.welcome {
    font-size: 3vh;
    font-weight: 600;
}

.ifm div {
    padding-top: 1vh;
}


.ifm img {
    /* display: flex;
			width: 40px;
			height: 40px;
			border-radius: 50%; */
}

.logo {
    display: flex;
    justify-content: center;
}

.container {
    width: 92%;
    color: #fff;
    background: linear-gradient(90deg, #6529c9, #cc2996);
    border-radius: 22px;

    padding-right: 30px;
    padding-left: 30px;
    margin-right: auto;
    margin-left: auto;
}

.ifmList {
    border-radius: 0 0 16px 16px;
    padding: 0 20px;
    box-sizing: border-box;
}

.ifmList ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 160px;
}

.ifmList ul li {
    text-align: left;
    font-size: 24px;
}

.ifmList ul li .num {
    font-size: 28px;
}

.ifmList ul li .tips {
    font-size: 28px;
    margin-top: 20px;
}

.tabsList {
    display: flex;
    align-items: center;
    width: 20vh;
    width: 92vw;
    justify-content: space-evenly;
    margin: 4vh 4vw 0 4vw;
    padding: 2vh 0vw;
    background-color: #fff;
    border-radius: 22px;
    flex-wrap: wrap;
}

.tabsList ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.tabsList ul li {
    width: 33.33%;
    font-size: 28px;
    text-align: center;
    margin-bottom: 10px;
    padding: 14px 20px;
}

.tabsList ul li div {
    color: #333;
    font-size: 24px;
}

.homepage {
    background: #fffeef !important;
}

.wrapper {
    height: auto;
    width: 100%;
    position: relative;
    transition: all ease 0.5s;
    -webkit-transition: all ease 0.5s;
    -moz-transition: all ease 0.5s;
    -ms-transition: all ease 0.5s;
    margin-left: 0;
    z-index: 1;
    // min-height: 100vh;
    /* padding-bottom: 80px; */
}

.titleList {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    box-sizing: border-box;
    width: 92vw;
    height: 10vh;
    margin: 0 4vw;
    flex-direction: row;
}

.titleList>.name {
    display: flex;
    height: 7vh;
    color: #333333;
    font-size: 32px;
    font-weight: 700;
    border-radius: 20px;
    width: 44vw;
    background-color: #fff;
    justify-content: flex-end;
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: center;
}

.titleList>.name img {
    width: 11vw;
}

.titleList>.name span {
    line-height: 4vh;
}


.actives {
    background: #ffb900 !important;
}

.rockList {
    margin-top: 100px;
}


.list {
    /* padding:0 20px; */
    /* margin-top:20px; */
}

.list,
.val {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #f5f5f5;
    padding: 20px;
    width: 100%;
    /* padding:0 20px; */
}

.list>.val>.lef {
    margin-right: 16px;
    display: inline-block;
    width: 100px;
    height: 100px;
    overflow: hidden;
}

.list>.val>.lef>img {
    display: inline-block;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    /*background: red;*/


}

.list>.val>.rig>.nav {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 12px;
}

.list>.val>.rig>.navVal {
    font-size: 24px;

    margin-bottom: 12px;
}
</style>